import React, { useMemo } from "react";
import PropTypes from "prop-types";
import {
  HeaderProfileMenu,
  HeaderProfileMenuItem,
  HeaderProfilePopoverContentContainer,
  HeaderProfilePopoverContentHeaderContainer,
  ProfileDetails,
  ProfileInitials,
  ProfileMail,
  ProfileName,
} from "./HeaderProfilePopoverContent.styled";
import { PAGES } from "constants/pages";
import { useMyUserQuery } from "features/user/usersApiSlice";
import { useTranslation } from "react-i18next";
import { logOut } from "features/auth/authSlice";
import { useDispatch } from "react-redux";

const HeaderProfilePopoverContent = () => {
  const { data } = useMyUserQuery(); //eslint-disable-line
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const myInitials = useMemo(() => {
    // return `${data?.firstName?.[0]}${data?.lastName?.[0]}`
    return "AM";
  }, [data]);

  const myName = useMemo(() => {
    // return `${data?.firstName} ${data?.lastName}`
    return "Andrew Marks";
  }, [data]);

  const myMail = useMemo(() => {
    // return `${data?.mail}`
    return "dummymail@dilig.net";
  }, [data]);

  const handleLogout = () => {
    dispatch(logOut());
  };

  return (
    <HeaderProfilePopoverContentContainer>
      <HeaderProfilePopoverContentHeaderContainer>
        <ProfileInitials to={PAGES.PROFILE.route}>{myInitials}</ProfileInitials>
        <ProfileDetails>
          <ProfileName to={PAGES.PROFILE.route}>{myName}</ProfileName>
          <ProfileMail>{myMail}</ProfileMail>
        </ProfileDetails>
      </HeaderProfilePopoverContentHeaderContainer>
      <HeaderProfileMenu>
        <HeaderProfileMenuItem>{t("pages.settings")}</HeaderProfileMenuItem>
        <HeaderProfileMenuItem onClick={handleLogout}>
          {t("common.logout")}
        </HeaderProfileMenuItem>
      </HeaderProfileMenu>
    </HeaderProfilePopoverContentContainer>
  );
};

HeaderProfilePopoverContent.propTypes = {
  children: PropTypes.node,
};

export default HeaderProfilePopoverContent;
