import React, { useRef, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useDropzone } from "react-dropzone";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  AddFile,
  AdditionalDescription,
  ButtonsContainer,
  ImageButton,
  ImageContainer,
  ImagePickerContainer,
  ImagePickerContentContainer,
  ImagePickerDescription,
  ImagePickerDescriptionText,
  ImagePickerLabel,
  ImageView,
} from "./ImagePicker.styled";

const ImagePicker = (props) => {
  const fileInputRef = useRef(null);
  const [image, setImage] = useState("");
  const [imageSizes, setImageSizes] = useState({
    width: props?.width,
    height: props?.height,
  });

  // Drag and drop image
  const { getRootProps } = useDropzone({
    accept: {
      "image/*": [".jpeg", ".jpg", ".png"],
    },
    noClick: true,
    onDrop: (acceptedFiles) => {
      if (!props?.disabled) {
        handleImage(acceptedFiles[0]);
      }
    },
  });

  const setContainerSize = (width, height) => {
    let newWidth = props?.minWidth;
    if (width >= props?.minWidth) {
      if (width <= props?.maxWidth) {
        newWidth = width;
      } else {
        newWidth = props?.maxWidth;
      }
    }
    let newHeight = props?.minHeight;
    if (height >= props?.minHeight) {
      if (height <= props?.maxHeight) {
        newHeight = height;
      } else {
        newHeight = props?.maxHeight;
      }
    }
    setImageSizes({
      width: newWidth + 2,
      height: newHeight + 2,
    });
  };

  useEffect(() => {
    // Set width and height of inputted image
    if (image?.length > 0) {
      const demoImg = new Image();
      demoImg.src = image;
      demoImg.onload = () => {
        setContainerSize(demoImg.naturalWidth, demoImg.naturalHeight);
      };
    }
  }, [image]);

  // Simulate click on file input
  const handleChange = () => {
    fileInputRef.current.value = "";
    fileInputRef.current.children[0].click();
  };

  const handleImage = (file) => {
    if (
      file.type !== "image/jpeg" &&
      file.type !== "image/jpg" &&
      file.type !== "image/png"
    )
      return;
    let reader = new FileReader();
    reader.onload = function (ev) {
      setImage(ev.target.result);
      props?.handleImage(ev.target.result);
    };
    reader.readAsDataURL(file);
  };

  const handleDelete = () => {
    setImage("");
    props?.handleImage("");
  };

  return (
    <ImagePickerContainer
      onClick={image === "" ? handleChange : () => {}}
      sx={{ display: "flex", flexDirection: "column", gap: 4, marginTop: 2 }}
    >
      {props?.label && <ImagePickerLabel>{props.label}</ImagePickerLabel>}
      <ImagePickerContentContainer
        height={imageSizes.height}
        width={imageSizes.width}
        component="form"
        {...getRootProps()}
        sx={{
          width: props?.width,
          height: props?.height,
          maxHeight: props?.maxHeight,
          maxWidth: props?.maxWidth,
        }}
      >
        {!image && (
          <ImagePickerDescription>
            <ImagePickerDescriptionText>
              {props?.descriptionText}
            </ImagePickerDescriptionText>
            {props?.additionalDescriptionText && (
              <AdditionalDescription>
                {props?.additionalDescriptionText}
              </AdditionalDescription>
            )}
          </ImagePickerDescription>
        )}
        {/* ****** */}

        <AddFile
          sx={{ display: "none" }}
          type="file"
          ref={fileInputRef}
          onInput={(event) => handleImage(event.target.files[0])}
          accept=".jpg, .jpeg, .png"
          formEncType="multipart/form-data"
        />

        {image && (
          <ImageContainer
            sx={{
              display: "flex",
              position: "relative",
            }}
          >
            <ImageView
              component="img"
              sx={{
                maxHeight: props?.maxHeight,
                maxWidth: props?.maxWidth,
              }}
              alt="Profile picture."
              src={image}
            />
          </ImageContainer>
        )}
        {props.children}
      </ImagePickerContentContainer>
      {image && (
        <ButtonsContainer>
          <ImageButton onClick={() => handleChange()}>
            <EditIcon />
          </ImageButton>
          <ImageButton onClick={() => handleDelete()}>
            <DeleteIcon />
          </ImageButton>
        </ButtonsContainer>
      )}
    </ImagePickerContainer>
  );
};
ImagePicker.propTypes = {
  children: PropTypes.node,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  descriptionText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  maxWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  maxHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  minWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  minHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  additionalDescriptionText: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]),
  required: PropTypes.bool,
  handleImage: PropTypes.func,
};

ImagePicker.defaultProps = {
  descriptionText: "Choose profile image",
  width: 245,
  height: 140,
  maxWidth: 245,
  maxHeight: 140,
  minWidth: 50,
  minHeight: 50,
  disabled: false,
};
export default ImagePicker;
