import { setBreadcrumbs } from "features/app/appSlice";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { getBreadcrumbs } from "util/routeHelpers";

const useBreadcrumbs = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setBreadcrumbs(getBreadcrumbs(location.pathname)));
  }, [location]);
  return {};
};

export default useBreadcrumbs;
