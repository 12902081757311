import { apiSlice } from "features/api/apiSlice";

const notificationTag = "notifications";

const userTag = "user";

export const usersApiSlice = apiSlice.injectEndpoints({
  tagTypes: [notificationTag, userTag],
  endpoints: (builder) => ({
    myUser: builder.query({
      query: () => ({
        url: "api/users/me",
      }),
      providesTags: [userTag],
    }),
    notifications: builder.query({
      query: () => ({
        url: "api/user/me/notifications",
      }),
      providesTags: [notificationTag],
    }),
    seeNotification: builder.mutation({
      query: (notificationId) => ({
        url: `api/user/me/notifications/${notificationId}`,
        method: "PATCH",
      }),
      invalidatesTags: [notificationTag],
    }),
    updateUserInfo: builder.mutation({
      query: (data) => ({
        url: `/api/users/${data?.userId}`,
        method: "PUT",
        body: data?.userInfo,
      }),
      invalidatesTags: [userTag],
    }),
  }),
});

export const {
  useMyUserQuery,
  useUpdateUserInfoMutation,
  useNotificationsQuery,
  useSeeNotificationMutation,
} = usersApiSlice;
