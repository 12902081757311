import { Box, Grid } from "@mui/material";
import styled from "styled-components";

export const DashboardContainer = styled(Box)`
  margin: 0px 32px;
`;

export const GridContainer = styled(Grid)`
  padding: 40px;
  display: flex;
  justify-content: center;
`;
export const GridItem = styled(Grid)``;
