import React from "react";
import {
  ButtonsContainer,
  DeleteButton,
  EditButton,
} from "./ActionButtons.styled";
//import { ButtonsContainer, DeleteButton } from "./ActionButtons.styled";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

const ActionButtons = (props) => {
  return (
    <ButtonsContainer>
      <EditButton onClick={() => console.log(props)}>
        <EditIcon />
      </EditButton>
      <DeleteButton onClick={() => console.log(props)}>
        <DeleteIcon />
      </DeleteButton>
    </ButtonsContainer>
  );
};

export default ActionButtons;
