import React from "react";
import PropTypes from "prop-types";
import {
  ButtonsContainer,
  SelectOption,
  TableButton,
  TableSelect,
} from "./TableControllButtons.styled";
import { useTranslation } from "react-i18next";

const TableControllButtons = ({ table }) => {
  const { t } = useTranslation();
  return (
    <ButtonsContainer>
      <TableButton
        onClick={() => table.setPageIndex(0)}
        disabled={!table.getCanPreviousPage()}
      >
        {t("tables.firstPage")}
      </TableButton>
      <TableButton
        disabled={!table.getCanPreviousPage()}
        onClick={() => table.previousPage()}
      >
        {t("tables.previousPage")}
      </TableButton>
      <TableButton
        disabled={!table.getCanNextPage()}
        onClick={() => table.nextPage()}
      >
        {t("tables.nextPage")}
      </TableButton>
      <TableButton
        onClick={() => table.setPageIndex(table.getPageCount() - 1)}
        disabled={!table.getCanNextPage()}
      >
        {t("tables.lastPage")}
      </TableButton>
      <TableSelect
        value={table.getState().pagination.pageSize}
        onChange={(e) => {
          table.setPageSize(Number(e.target.value));
        }}
      >
        {[10, 20, 30, 40, 50].map((pageSize) => (
          <SelectOption key={pageSize} value={pageSize}>
            {t("tables.show")} {pageSize}
          </SelectOption>
        ))}
      </TableSelect>
    </ButtonsContainer>
  );
};
TableControllButtons.propTypes = {
  table: PropTypes.any,
};

export default TableControllButtons;
