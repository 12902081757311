export default {
  app: {
    title: "React template",
  },
  refresh: {
    title: "Are you active?",
    cta: "You were registered as not active, please confirm that you are active in the next minute, if you don't you will be logged out.",
  },
  common: {
    language: "Jezik",
    english: "Engleski",
    serbian: "Srpski",
    dataGridExample: "Primer Data Grid-a",
    close: "Close",
    trademark: "TM",
    search: "Pretraga",
    error: "Greška",
    continue: "Nastavite",
    labelUsername: "Korisničko ime",
    labelEmail: "E-mail",
    labelPassword: "Šifra",
    next: "Napred",
    nextPage: "Sledeća stranica",
    previousPage: "Predhodna stranica",
    back: "Nazad",
    goBack: "Idite nazad",
    ok: "U redu",
    done: "Gotovo",
    confirm: "Potvrdite",
    printDownload: "Print/Download",
    cancel: "Cancel",
    remove: "Remove",
    invite: "Invite",
    save: "Save",
    complete: "Complete",
    download: "Download",
    yes: "Yes",
    no: "No",
    to: "to",
    select: "Select...",
    none: "None",
    date: {
      range: "{{start}} do {{end}}",
    },
    logout: "Izloguj se",
    seeMore: "Vidi još",
    choose: "Izaberi",
  },
  components: {
    autocomplete: "Autocomplete",
    multiselect: "Multiselect",
  },
  tables: {
    nextPage: "Sledeća stranica",
    previousPage: "Predhodna stranica",
    firstPage: "Prva stranica",
    lastPage: "Zadnja stranica",
    show: "Prikaži",
  },
  notifications: {
    title: "Obaveštenja",
  },
  pages: {
    home: "Početna",
    login: "Login",
    register: "Register",
    forgotPassword: "Zaboravljena lozinka",
    error: "Pogrešna stranica",
    notFound: "Nije pronađena stranica",
    profile: "Profilna stranica",
    settings: "Podešavanja",
    dashboard: "Komandna tabla",
    components: "Komponente",
  },
  register: {
    registerTitle: "Register",
    usernameRequired: "Username is required.",
    emailFormat: "Invalid email address format.",
    emailRequired: "An email or username is required.",
    passwordLength: "Your password contain between 8 and 50 characters.",
    passwordRequired: "A Password is required.",
  },
  login: {
    welcome: "React template",
    dontHaveAccount: "Nemate nalog? ",
    emailFormat: "Loš format email-a",
    emailRequired: "Email/korisničko ime je obavezno",
    noUsers: "Ne postoji korisnik",
    passwordStrength: "Your password is {{strength}}.",
    passwordLength: "Your password contain between 8 and 50 characters.",
    signUpRecommendation: "Registrujte se",
    email: "Please enter your email address or username to log in:",
    logInTitle: "Prijava",
    logIn: "Ulogujte se",
    signUp: "Sign Up",
    usernameRequired: "Username is required.",
    passwordRequired: "A Password is required.",
    forgotYourPassword: "Zaboravili ste šifru?",
    forgotPasswordEmail: "Email",
    useDifferentEmail: "Use different email address or username",
  },
  password: {
    weak: "weak",
    average: "average",
    good: "good",
    strong: "strong",
  },
  forgotPassword: {
    title: "Forgot Password",
    label: "Send email",
    emailRequired: "An email is required.",
    emailFormat: "Invalid email address format.",
    forgotPassword: {
      title: "Forgot Password",
      subtitle:
        "Please answer the security question to gain access to your account:",
      label: "Reset Password",
    },
  },
  notFound: {
    text: "We're sorry but we couldn't find the page you were looking for.",
    goBack: "Go back to homepage",
  },
  errorPage: {
    text: "We're sorry, an internal server error came up. Please be patient or try again later.",
    goBack: "Go back to homepage",
    logout: "Logout",
  },
  apiErrors: {
    ClientIpAddressIsNullOrEmpty: "Client Ip address is null or empty",
    UsernameDoesNotExist: "Username does not exist",
    WrongCredentials: "Wrong credentials",
    SomethingWentWrong: "Something went wrong",
    WrongPasswordAccountIsLocked: "Wrong credentials, account is locked",
    AccountIsLocked: "Account is locked",
  },
  date: {
    timespan: {
      yearsAgo: "Pre {{years}} godine",
      monthsAgo: "Pre {{months}} meseca",
      daysAgo: "Pre {{days}} dana",
      hoursAgo: "Pre {{hours}} sata",
      minutesAgo: "Pre {{minutes}} minuta",
      secondsAgo: "Pre {{seconds}} sekunde",
      now: "Upravo sada",
    },
  },
  dashboard: {
    charts: {
      lineChart: "Linijski grafikon",
      pieChart: "Kružni grafikon",
      barChart: "Dijagram",
      radarChart: "Radarski grafikon",
    },
  },
  profile: {
    image: "Slika profila",
    chooseImage: "Izaberi sliku za profil",
    form: {
      step1: "Lični podaci",
      step2: "Adresa",
      step3: "Posao",
    },
    labels: {
      firstName: "Ime",
      lastName: "Prezime",
      gender: "Pol",
      city: "Grad",
      street: "Ulica",
      postalCode: "Poštanski broj",
      companyName: "Ime firme",
      companyAddress: "Adresa firme",
      yearsInCompany: "Broj godina u firmi",
    },
  },
};
