import React, { useContext } from "react";
import PropTypes from "prop-types";
import DarkModeIcon from "@mui/icons-material/DarkMode";
import LightModeIcon from "@mui/icons-material/LightMode";
import { ColorModeContext } from "context/ColorModeContext";
import { DARK_THEME } from "constants/themeConstants";
import { HeaderIconContainer } from "components/Header/Header.styled";

const HeaderThemeChooser = () => {
  const themeCtx = useContext(ColorModeContext);

  const handleChangeTheme = () => {
    themeCtx?.changeTheme?.();
  };
  return (
    <HeaderIconContainer onClick={handleChangeTheme}>
      {themeCtx?.currentTheme === DARK_THEME ? (
        <LightModeIcon />
      ) : (
        <DarkModeIcon />
      )}
    </HeaderIconContainer>
  );
};

HeaderThemeChooser.propTypes = {
  children: PropTypes.node,
};

export default HeaderThemeChooser;
