import styled from "styled-components";
import { Box, TextField, Typography } from "@mui/material";

export const FieldContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  margin-bottom: 5px;
`;

export const Field = styled(TextField)``;

export const ErrorMessage = styled(Typography)`
  min-height: 20px;
`;
