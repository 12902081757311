import { Box, Typography } from "@mui/material";
import styled from "styled-components";

export const ComponentsContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 600px;
  gap: 30px;
`;

export const ComponentsMainContainer = styled(Box)`
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 50px;
`;

export const MaterialIconsLink = styled(Typography)`
  margin-bottom: -30px;
`;
