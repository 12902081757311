import { Box, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import styled from "styled-components";

export const HeaderNotificationsContentContainer = styled(Box)`
  width: 350px;
  padding: 0 8px;
  padding-bottom: 8px;
  cursor: default;
  background-color: ${(props) => props?.theme?.colors?.primaryDark};
  border-radius: 6px;
`;
export const HeaderNotificationsContentHeader = styled(Box)`
  display: flex;
  padding: 0 8px;
  justify-content: space-between;
  height: 60px;
  align-items: center;
`;
export const HeaderNotificationsContentHeaderTitle = styled(Typography)`
  font-size: 18px;
  font-weight: 700;
  font-family: Inter;
  color: ${(props) => props?.theme?.colors?.textColor};
`;
export const HeaderNotificationsContentHeaderSeeMore = styled(Link)`
  color: ${(props) => props?.theme?.colors?.textColor};
`;
export const HeaderLatestNotificationsContainer = styled(Box)`
  display: flex;
  flex-direction: column;
`;
