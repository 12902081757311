import styled, { css } from "styled-components";
import Autocomplete from "@mui/material/Autocomplete";
import {
  Box,
  Chip,
  ListItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { hexToRGB } from "util/helpers/colorHelper";
import CloseIcon from "@mui/icons-material/Close";

export const AutocompleteDropdown = styled(Autocomplete)`
  & > div > div {
    border-radius: 8px;
    height: auto;
    ${(props) =>
      props?.disabled &&
      css`
        background-color: #efefef;
      `}
  }
  & input {
    padding-top: 6px !important;
  & input:disabled {
    -webkit-text-fill-color: #252525 !important;
  }
  & input::placeholder {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    opacity: 1 !important;
    color: #8f8f8f !important;
  }
  & .MuiAutocomplete-endAdornment {
    bottom: 14px !important;
    top: auto !important;
    right: 9px !important;
  }
`;

export const AutocompleteInputField = styled(TextField)`
  & fieldset {
    border: 1px solid #757575 !important;
    box-shadow: 0px 1px 2px ${hexToRGB("#101828", 0.05)};
  }
  & input {
    font-family: Inter;
    font-size: 16px;
    font-feature-settings: "salt" on;
    &::placeholder {
      -webkit-text-fill-color: #757575} !important;
      color: #757575 !important;
      opacity: 1;
    }
  }
  & > .Mui-error {
    & fieldset {
      border: 1px solid #FDA29B !important;
      box-shadow: 0px 1px 2px
        ${hexToRGB("#101828", 0.05)};
    }
  }
  & > .Mui-disabled {
    & input {
      -webkit-text-fill-color: #252525 !important;
      &::placeholder 
        -webkit-text-fill-color: "#252525 !important;
        opacity: 1;
      }
    }
  }
`;

export const AutocompleteItem = styled(ListItem)``;

export const FieldLabel = styled(Typography)`
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 8px;
  color: "#4B515F";
  ${(props) =>
    props?.$required &&
    css`
      &::after {
        content: " *";
        color: #fa1100;
      }
    `}
  ${(props) =>
    props?.$setMargin &&
    css`
      margin-top: 32px;
    `}
`;

export const AutocompleteContainer = styled(Box)`
  width: 100%;
`;

export const ErrorMessage = styled(Typography)`
  font-size: 12px;
  font-weight: 400;
  color: #fa1100;
  text-align: ${(props) => props?.$rightAlign && "right"};
  margin-top: 3px;
`;

export const TagsContainer = styled(Stack)`
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-bottom: 5px;
  flex-wrap: wrap;
  gap: 4px;
`;

export const Tag = styled(Chip)`
  padding: 0px 8px 0px 8px;
  height: 28px;
  border-radius: 24px;
  gap: 4px;
  background: #f2fafe;
  color: #1c64b9;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  font-feature-settings: "salt" on;
`;
export const DeleteButton = styled(CloseIcon)`
  min-width: 14px;
  min-height: 14px;
  max-width: 14px;
  max-height: 14px;
`;
