import React from "react";
import {
  RadarChart,
  Radar,
  Legend,
  ResponsiveContainer,
  PolarGrid,
  PolarAngleAxis,
  PolarRadiusAxis,
} from "recharts";
import PropTypes from "prop-types";

const RadarChartComponent = ({ chartData }) => {
  return (
    <ResponsiveContainer width="100%" height="100%">
      <RadarChart cx="50%" cy="45%" outerRadius={100} data={chartData.data}>
        <PolarGrid gridType="circle" />
        <PolarAngleAxis dataKey={chartData.AngleAxisKey} />
        <PolarRadiusAxis dataKey={chartData.RadiusAxisKey} />
        {chartData?.radars?.map((radar, index) => {
          return (
            <Radar
              key={index}
              name={radar.name}
              dataKey={radar.dataKey}
              stroke={radar.stroke}
              fill={radar.fill}
              fillOpacity={0.6}
            />
          );
        })}
        <Legend />
      </RadarChart>
    </ResponsiveContainer>
  );
};

RadarChartComponent.propTypes = {
  chartData: PropTypes.object.isRequired,
};

export default RadarChartComponent;
