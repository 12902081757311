import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import styled from "styled-components";
import InfoIcon from "@mui/icons-material/Info";

export const Info = styled(InfoIcon)`
  margin-left: 9px;
  color: #1976d2;
`;

export const TableContainer = styled(Paper)`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: 770px;
  overflow-y: auto;
  margin: 15px 0px;
`;

export const CustomTable = styled(Table)``;

export const THead = styled(TableHead)`
  background-color: rgb(196, 223, 223);
`;

export const TBody = styled(TableBody)``;

export const TRow = styled(TableRow)``;

export const TCell = styled(TableCell)``;

export const THeadContainer = styled(Box)``;
