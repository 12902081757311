import React from "react";
import PropTypes from "prop-types";
import CustomField from "components/Fields/CustomField/CustomField";
import { FieldsContainer } from "../ProfileDetails.styled";
import { useTranslation } from "react-i18next";

const AddressDataForm = ({ errors, values, handleChange }) => {
  const { t } = useTranslation();
  return (
    <FieldsContainer>
      <CustomField
        type="text"
        name="city"
        value={values.city}
        handleChange={handleChange}
        error={errors.city}
        label={t("profile.labels.city")}
      />
      <CustomField
        type="text"
        name="street"
        value={values.street}
        handleChange={handleChange}
        error={errors.street}
        label={t("profile.labels.street")}
      />
      <CustomField
        type="number"
        name="postalCode"
        value={values.postalCode}
        handleChange={handleChange}
        error={errors.postalCode}
        label={t("profile.labels.postalCode")}
      />
    </FieldsContainer>
  );
};

AddressDataForm.propTypes = {
  errors: PropTypes.string,
  values: PropTypes.any,
  handleChange: PropTypes.func,
};

export default AddressDataForm;
