import { Box, TextField, Typography } from "@mui/material";
import styled from "styled-components";

export const MainContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 20px 0px;
`;

export const GlobalFilterInput = styled(TextField)`
  width: 50%;
`;

export const Title = styled(Typography)`
  margin-bottom: 40px;
`;
