import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";

const appSlice = createSlice({
  name: "app",
  initialState: {
    breadcrumbs: [],
  },
  reducers: {
    setBreadcrumbs: (state, action) => {
      state.breadcrumbs = action.payload;
    },
  },
});

export const { setBreadcrumbs } = appSlice.actions;
export default appSlice.reducer;

const appSelector = (state) => state.app;
export const selectBreadcrumbs = createSelector(
  appSelector,
  (state) => state.breadcrumbs
);
