import { useFormikWizard } from "formik-wizard-form";
import React, { useState } from "react";
import steps from "../../../steps/profileSteps";
import { profileInitialValues } from "initialValues/profileInitialValues";
import PropTypes from "prop-types";
import {
  ContentContainer,
  FormButton,
  FormButtonsContainer,
  FormContainer,
  SingleStep,
  SingleStepLabel,
  StepperContainer,
  StepsContainer,
} from "./ProfileDetails.styled";
import { useTranslation } from "react-i18next";

const ProfileDetails = ({ handleSubmit, userData }) => {
  const [finished, setFinished] = useState(false);
  const { t } = useTranslation();

  const {
    renderComponent,
    handlePrev,
    handleNext,
    isPrevDisabled,
    isLastStep,
    currentStepIndex,
  } = useFormikWizard({
    initialValues: profileInitialValues(userData),
    onSubmit: (values) => {
      handleSubmit(values);
      setFinished(true);
    },
    validateOnChange: false,
    validateOnNext: true,
    activeStepIndex: 0,
    steps: steps,
    enableReinitialize: true,
  });

  return (
    <FormContainer elevation={5}>
      <StepsContainer>
        <StepperContainer activeStep={currentStepIndex}>
          <SingleStep completed={currentStepIndex > 0}>
            <SingleStepLabel>{t("profile.form.step1")}</SingleStepLabel>
          </SingleStep>
          <SingleStep completed={currentStepIndex > 1}>
            <SingleStepLabel>{t("profile.form.step2")}</SingleStepLabel>
          </SingleStep>
          <SingleStep completed={finished}>
            <SingleStepLabel>{t("profile.form.step3")}</SingleStepLabel>
          </SingleStep>
        </StepperContainer>
      </StepsContainer>
      <ContentContainer my="2rem">{renderComponent()}</ContentContainer>
      <FormButtonsContainer>
        <FormButton
          variant="contained"
          disabled={isPrevDisabled || finished}
          type="primary"
          onClick={handlePrev}
        >
          {t("common.back")}
        </FormButton>
        <FormButton variant="contained" type="primary" onClick={handleNext}>
          {isLastStep ? t("common.done") : t("common.next")}
        </FormButton>
      </FormButtonsContainer>
    </FormContainer>
  );
};

ProfileDetails.propTypes = {
  handleSubmit: PropTypes.func,
  userData: PropTypes.object,
};
export default ProfileDetails;
