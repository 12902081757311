import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import { LogoContainer } from "./Logo.styled";
//Import logo

const Logo = forwardRef((props, ref) => {
  return (
    <LogoContainer
      {...props}
      ref={ref}
      // src={LogoImage}
    >
      Logo
    </LogoContainer>
  );
});

Logo.displayName = "Logo";

Logo.propTypes = {
  children: PropTypes.node,
};

export default Logo;
