import React from "react";
import PropTypes from "prop-types";
import { headerNavigationConstants } from "constants/navigationConstants";
import { HeaderNavigationContainer } from "./HeaderNavigation.styled";
import HeaderNavigationItem from "./HeaderNavigationItem/HeaderNavigationItem";

const HeaderNavigation = () => {
  return (
    <HeaderNavigationContainer>
      {headerNavigationConstants.map?.((singleNavigationItem, index) => (
        <HeaderNavigationItem
          key={index}
          route={singleNavigationItem}
        />
      ))}
    </HeaderNavigationContainer>
  );
};

HeaderNavigation.propTypes = {
  children: PropTypes.node,
};

export default HeaderNavigation;
