export const profileInitialValues = (values) => {
  console.log(values);
  return {
    firstName: values?.firstName || "",
    lastName: values?.lastName || "",
    gender: values?.gender || "",
    city: values?.city || "",
    street: values?.street || "",
    postalCode: values?.postalCode || "",
    companyName: values?.companyName || "",
    companyAddress: values?.companyAddress || "",
    yearsInCompany: values?.yearsInCompany || "",
  };
};
