import React from "react";
import PropTypes from "prop-types";
import {
  CheckboxFormControl,
  CheckboxFormControlLabel,
  CheckboxFormGroup,
  CheckboxFormLabel,
  Container,
  CustomCheckbox,
} from "./CheckboxComponent.styled";

const CheckboxComponent = ({ data, selected, handleChange }) => {
  return (
    <Container>
      <CheckboxFormControl
        sx={{ m: 3 }}
        component="fieldset"
        variant="standard"
      >
        <CheckboxFormLabel component="legend">Checkbox</CheckboxFormLabel>
        <CheckboxFormGroup>
          {data.map((item, index) => (
            <CheckboxFormControlLabel
              key={index}
              control={
                <CustomCheckbox
                  checked={item.id === JSON.parse(selected)?.id}
                  onChange={handleChange}
                  value={JSON.stringify(item)}
                  name={item.name}
                />
              }
              label={item.name}
            />
          ))}
        </CheckboxFormGroup>
      </CheckboxFormControl>
    </Container>
  );
};

CheckboxComponent.propTypes = {
  data: PropTypes.array,
  selected: PropTypes.any,
  handleChange: PropTypes.func,
};

export default CheckboxComponent;
