import React, { createContext } from "react";
import PropTypes from "prop-types";
// import { ThemeProvider } from "@mui/material/styles";
import useTheme from "hooks/useTheme";
import { ThemeProvider } from "styled-components";

export const ColorModeContext = createContext();

const ColorModeProvider = ({ children }) => {
  const [changeTheme, theme, currentTheme] = useTheme();
  return (
    <ColorModeContext.Provider value={{ changeTheme, theme, currentTheme }}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </ColorModeContext.Provider>
  );
};

ColorModeProvider.propTypes = {
  children: PropTypes.node,
};

export default ColorModeProvider;
