import AddressDataForm from "components/Profile/ProfileDetails/AddressDataForm/AddressDataForm";
import CompanyDataForm from "components/Profile/ProfileDetails/CompanyDataForm/CompanyDataForm";
import PersonalDataForm from "components/Profile/ProfileDetails/PersonalDataForm/PersonalDataForm";
import addressValidation from "validations/profile/addressValidation";
import companyDataValidation from "validations/profile/companyDataValidation";
import personalDataValidation from "validations/profile/personalDataValidation";

export default [
  {
    component: PersonalDataForm,
    validationSchema: personalDataValidation,
  },
  {
    component: AddressDataForm,
    validationSchema: addressValidation,
  },
  {
    component: CompanyDataForm,
    validationSchema: companyDataValidation,
  },
];
