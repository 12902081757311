import { Box, Card, Typography } from "@mui/material";
import styled from "styled-components";

export const ImageContainer = styled(Card)`
  height: 425px;
  display: flex;
  padding: 16px;
  flex-direction: column;
`;

export const TitleContainer = styled(Box)`
  margin-bottom: 80px;
`;

export const Title = styled(Typography)``;
