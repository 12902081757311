import React from "react";
import { createColumnHelper } from "@tanstack/react-table";
import ActionButtons from "./ActionButtons/ActionButtons";
import { ExpandButton } from "./ActionButtons/ActionButtons.styled";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

const columnHelper = createColumnHelper();

export const columns = [
  columnHelper.display({
    id: "expand",
    cell: ({ row }) => {
      return row.getCanExpand() ? (
        <ExpandButton
          {...{
            onClick: row.getToggleExpandedHandler(),
            style: { cursor: "pointer" },
          }}
        >
          {row.getIsExpanded() ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </ExpandButton>
      ) : (
        "🔵"
      );
    },
  }),
  columnHelper.accessor((row) => row.name, {
    id: "Name",
    cell: (info) => info.getValue(),
    header: () => <span>Name</span>,
  }),
  columnHelper.accessor((row) => row.genre, {
    id: "Genre",
    cell: (info) => info.getValue(),
    header: () => <span>Genre</span>,
  }),
  columnHelper.accessor((row) => row.rating, {
    id: "Rating",
    cell: (info) => info.getValue(),
    header: () => <span>Rating</span>,
  }),
  columnHelper.display({
    id: "actions",
    cell: (props) => <ActionButtons row={props.row} />,
  }),
];
