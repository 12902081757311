import React from "react";
import PropTypes from "prop-types";
import {
  ColumnFilterInput,
  FilterConatiner,
  FilterLabel,
  FiltersConatiner,
  RangeFilterContainer,
} from "./Filters.styled";

const Filters = ({ table }) => {
  return (
    <FiltersConatiner>
      {table.getHeaderGroups().map((headerGroup) =>
        headerGroup.headers.map((header, index) => {
          return (
            header.column.getCanFilter() &&
            (typeof table
              .getPreFilteredRowModel()
              .flatRows[0]?.getValue(header.column.id) === "number" ? (
              <FilterConatiner>
                <FilterLabel>{header.column.columnDef.header()}</FilterLabel>
                <RangeFilterContainer>
                  <ColumnFilterInput
                    key={index}
                    type="number"
                    placeholder="Min"
                    value={header.column.getFilterValue?.()?.[0] ?? ""}
                    onChange={(e) => {
                      header.column.setFilterValue((old) => [
                        e.target.value,
                        old?.[1],
                      ]);
                    }}
                  />
                  <ColumnFilterInput
                    key={index}
                    type="number"
                    placeholder="Max"
                    value={header.column.getFilterValue?.()?.[1] ?? ""}
                    onChange={(e) => {
                      header.column.setFilterValue((old) => [
                        old?.[0],
                        e.target.value,
                      ]);
                    }}
                  />
                </RangeFilterContainer>
              </FilterConatiner>
            ) : (
              <FilterConatiner>
                <FilterLabel>{header.column.columnDef.header()}</FilterLabel>
                <ColumnFilterInput
                  key={index}
                  value={header.column.getFilterValue() ?? ""}
                  onChange={(e) => {
                    header.column.setFilterValue(e.target.value);
                  }}
                />
              </FilterConatiner>
            ))
          );
        })
      )}
    </FiltersConatiner>
  );
};

Filters.propTypes = {
  table: PropTypes.any,
};

export default Filters;
