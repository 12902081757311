import React from "react";
// import NavbarComponent from "components/Navbar/NavbarComponent";
import { Box, Grid } from "@mui/material";
import Modals from "components/Modals/ModalsExample";
import DataGridExample from "components/DataGrid/DataGridExample";
import { useAllPostsQuery } from "features/posts/postsApiSlice";
import BackdropComponent from "components/Backdrop/BackdropComponent";
import BasicTable from "components/Tables/BasicTable/BasicTable";
import ExpandingTable from "components/Tables/ExpandingTable/ExpandingTable";
import FiltersTable from "components/Tables/FiltersTable/FiltersTable";

const HomePage = () => {
  const { data, isLoading } = useAllPostsQuery();
  console.log("posts", data?.data);

  return (
    <>
      <BackdropComponent position="absolute" isLoading={isLoading} />
      <Box sx={{ mt: 4, mx: 4 }}>
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={12} md={3}>
            <Modals />
          </Grid>
          <Grid item xs={12} md={6}>
            <DataGridExample />
          </Grid>
          <Grid item xs={12} md={9}>
            <BasicTable />
          </Grid>
          <Grid item xs={12} md={9}>
            <ExpandingTable />
          </Grid>
          <Grid item xs={12} md={9}>
            <FiltersTable />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default HomePage;
