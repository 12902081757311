import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import PropTypes from "prop-types";

const BarChartComponent = ({ chartData }) => {
  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart
        data={chartData.data}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey={chartData.XAxisKey} />
        <YAxis dataKey={chartData.YAxisKey} />
        <Tooltip />
        <Legend />
        {chartData?.bars?.map((bar, index) => {
          return <Bar key={index} dataKey={bar.dataKey} fill={bar.fill} />;
        })}
      </BarChart>
    </ResponsiveContainer>
  );
};

BarChartComponent.propTypes = {
  chartData: PropTypes.object.isRequired,
};
export default BarChartComponent;
