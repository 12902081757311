import React, { useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import {
  SingleNotificationContainer,
  SingleNotificationContent,
  SingleNotificationDate,
  SingleNotificationDetails,
  SingleNotificationProfile,
  SingleNotificationUnseen,
} from "./SingleNotification.styled";
import { formatTimeSpan } from "util/dateHelpers";
import { useSeeNotificationMutation } from "features/user/usersApiSlice";
import { makeErrorToastMessage } from "util/toastMessage";

const SingleNotification = (props) => {
  const [seeNotification, result] = useSeeNotificationMutation();
  const timespan = useMemo(
    () => formatTimeSpan(props?.notificationObject?.date),
    [props?.notificationObject?.date]
  );

  const handleSeeNotification = () => {
    seeNotification(2);
    console.log(result);
  };

  useEffect(() => {
    if (result.isError) {
      makeErrorToastMessage("Server error");
    }
  }, [result.isError]);

  return (
    <SingleNotificationContainer onClick={handleSeeNotification}>
      <SingleNotificationProfile>
        {props?.notificationObject?.userPicture}
      </SingleNotificationProfile>
      <SingleNotificationDetails>
        <SingleNotificationContent>
          {props?.notificationObject?.notificationText}
        </SingleNotificationContent>
        <SingleNotificationDate>{timespan}</SingleNotificationDate>
      </SingleNotificationDetails>
      {!props?.notificationObject?.seen && <SingleNotificationUnseen />}
    </SingleNotificationContainer>
  );
};

SingleNotification.propTypes = {
  children: PropTypes.node,
  notificationObject: PropTypes.shape({
    userPicture: PropTypes.oneOfType([PropTypes.element, PropTypes.node]),
    notificationText: PropTypes.node,
    date: PropTypes.object,
    seen: PropTypes.bool,
  }),
};

export default SingleNotification;
