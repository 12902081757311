import * as Yup from "yup";
import i18next from "i18next";

export default Yup.object().shape({
  companyName: Yup.string().required(
    i18next.t("profile.validation.companyName")
  ),
  companyAddress: Yup.string().required(
    i18next.t("profile.validation.companyAddress")
  ),
  yearsInCompany: Yup.number().required(
    i18next.t("profile.validation.yearsInCompany")
  ),
});
