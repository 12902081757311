import { Box, Typography } from "@mui/material";
import styled from "styled-components";

export const SingleNotificationContainer = styled(Box)`
  display: flex;
  gap: 8px;
  align-items: center;
  border-top: 1px solid ${(props) => props?.theme?.colors?.secondaryDark};
  padding: 6px 0px;
  cursor: pointer;
  &:hover {
    background-color: ${props => props?.theme?.colors?.secondaryDark};
    border-radius: 8px;
  }
`;
export const SingleNotificationProfile = styled(Box)``;
export const SingleNotificationDetails = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
export const SingleNotificationContent = styled(Typography)`
  font-family: Inter;
  color: ${(props) => props?.theme?.colors?.textColor};
  & * {
    margin: 0;
  }
`;
export const SingleNotificationDate = styled(Typography)`
  font-family: Inter;
  font-size: 12px;
  color: ${(props) => props?.theme?.colors?.textColor};
`;
export const SingleNotificationUnseen = styled(Box)`
  width: 8px;
  min-width: 8px;
  height: 8px;
  min-height: 8px;
  background-color: ${(props) => props?.theme?.colors?.primaryLighter};
  border-radius: 100%;
`;
