import { Box, Button, MenuItem, Select } from "@mui/material";
import styled from "styled-components";

export const ButtonsContainer = styled(Box)`
  display: flex;
  gap: 20px;
  justify-content: center;
  margin-top: 10px;
`;

export const TableButton = styled(Button)``;

export const TableSelect = styled(Select)`
  height: 40px;
`;

export const SelectOption = styled(MenuItem)``;
