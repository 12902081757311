import styled from "styled-components";
import { Box, Button, Input, Typography } from "@mui/material";

export const ImagePickerContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 16px;
  position: relative;
`;

export const ImagePickerLabel = styled(Typography)``;

export const AdditionalDescription = styled(Box)`
  font-weight: 300;
  font-size: 12px;
  line-height: 15px;
  font-feature-settings: "salt" on;
`;
export const ImagePickerDescriptionText = styled(Box)``;

export const ImagePickerContentContainer = styled(Box)`
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  border: 1px dashed black;
  position: relative;
  cursor: pointer;
  overflow: hidden;
`;
export const AddFile = styled(Input)`
  display: none;
`;

export const ImagePickerDescription = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 8px;
  & * {
    display: inline;
    font-size: 14px;
    text-align: center;
    & p {
    }
  }
`;

export const ImageContainer = styled(Box)`
  display: flex;
  position: relative;
`;
export const ImageView = styled(Box)``;

export const ButtonsContainer = styled(Box)`
  display: flex;
  position: absolute;
  z-index: 5;
  right: 4px;
  top: 4px;
  gap: 4px;
`;

export const ImageButton = styled(Button)`
  min-width: 0;
  height: 30px;
  background-color: white;
`;
