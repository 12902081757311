import { Box, TextField, Typography } from "@mui/material";
import styled from "styled-components";

export const FiltersConatiner = styled(Box)`
  display: flex;
  width: 100%;
  gap: 20px;
`;

export const FilterConatiner = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 10px;
  margin-bottom: 20px;
`;

export const RangeFilterContainer = styled(Box)`
  display: flex;
  width: 100%;
  gap: 10px;
`;

export const FilterLabel = styled(Typography)``;

export const ColumnFilterInput = styled(TextField)``;
