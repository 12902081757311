import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import PropTypes from "prop-types";

const LineChartComponent = ({ chartData }) => {
  return (
    <ResponsiveContainer width="100%" height="100%">
      <LineChart
        data={chartData.data}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey={chartData.XAxisKey} />
        <YAxis dataKey={chartData.YAxisKey} />
        <Tooltip />
        <Legend />
        {chartData?.lines?.map((line, index) => {
          return (
            <Line
              key={index}
              type={line.type}
              dataKey={line.dataKey}
              stroke={line.stroke}
            />
          );
        })}
      </LineChart>
    </ResponsiveContainer>
  );
};

LineChartComponent.propTypes = {
  chartData: PropTypes.object.isRequired,
};

export default LineChartComponent;
