import { apiSlice } from "features/api/apiSlice";

export const providerApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    providerLogin: builder.query({
      query: (data) => ({
        url: `api/auth/${data.provider}/callback${data.search}`,
      }),
    }),
  }),
});

export const { useProviderLoginQuery } = providerApiSlice;
