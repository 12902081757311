import AutocompleteField from "components/Fields/AutocompleteField/AutocompleteField";
import CustomField from "components/Fields/CustomField/CustomField";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import generate from "util/helpers/randomData";
import {
  ComponentsContainer,
  ComponentsMainContainer,
  MaterialIconsLink,
} from "./Components.styled";
import { formats, modules } from "constants/textEditorConstants";
import { Link } from "@mui/material";
import CheckboxComponent from "components/Fields/CheckboxComponent/CheckboxComponent";

const Components = () => {
  const [autocompleteValue, setAutocompleteValue] = useState(null);
  const [multiselectValue, setMultiselectValue] = useState([]);
  const [fieldValue, setFieldValue] = useState(null);
  const [editorValue, setEditorValue] = useState("");
  const [checkboxValue, setCheckboxValue] = useState(null);
  const [data, setData] = useState(generate(15));
  const { t } = useTranslation();

  console.log(setData);
  return (
    <ComponentsMainContainer>
      <ComponentsContainer>
        <MaterialIconsLink variant="h6">
          Material Icons -{" "}
          <Link href="https://mui.com/material-ui/material-icons/">
            https://mui.com/material-ui/material-icons/
          </Link>
        </MaterialIconsLink>
        <CheckboxComponent
          data={data.slice(0, 4)}
          selected={checkboxValue}
          handleChange={(e) => {
            console.log(e.target.value);
            setCheckboxValue(e.target.value);
          }}
        />
        <AutocompleteField
          options={data}
          setMargin
          label={t("common.choose")}
          title={t("components.autocomplete")}
          onChange={(query) => setAutocompleteValue(query?.id)}
          dropdownLabel={["name"]}
          defaultValue={autocompleteValue}
        />
        <AutocompleteField
          options={data}
          label={t("common.choose")}
          title={t("components.multiselect")}
          onChange={(query) => {
            console.log(query);
            setMultiselectValue(query);
          }}
          onDelete={(value) =>
            setMultiselectValue((prevValue) =>
              prevValue.filter((item) => item.id !== value.id)
            )
          }
          isMultiple
          dropdownLabel={["name"]}
          value={multiselectValue}
        />
        <CustomField
          type="text"
          name="firstName"
          value={fieldValue}
          handleChange={(e) => setFieldValue(e.target.value)}
          label={t("profile.labels.firstName")}
          displayIcon
          placeholder={t("profile.labels.firstName")}
        />
        <ReactQuill
          theme="snow"
          value={editorValue}
          onChange={setEditorValue}
          modules={modules}
          formats={formats}
        />
      </ComponentsContainer>
    </ComponentsMainContainer>
  );
};

export default Components;
