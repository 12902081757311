import React from "react";
import PropTypes from "prop-types";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { HeaderIconContainer } from "components/Header/Header.styled";
import PopoverComponent from "components/Popover/Popover";
import HeaderNotificationsContent from "./HeaderNotificationsContent/HeaderNotificationsContent";

const HeaderNotifications = () => {
  return (
    <PopoverComponent
      trigger={
        <HeaderIconContainer>
          <NotificationsIcon />
        </HeaderIconContainer>
      }
      contentContainerStyles={{
        "& .MuiPopover-paper": {
          borderRadius: "6px",
          backgroundColor: "transparent",
        },
      }}
      content={<HeaderNotificationsContent />}
      popoverProps={{
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      }}
    />
  );
};

HeaderNotifications.propTypes = {
  children: PropTypes.node,
};

export default HeaderNotifications;
