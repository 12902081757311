import React, { useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import PropTypes from "prop-types";
// import Backdrop from 'components/MUI/BackdropComponent';
import { useProviderLoginQuery } from "features/provider/providerApiSlice";
import { useDispatch } from "react-redux";
import { setCredetnials } from "features/auth/authSlice";
import BackdropComponent from "components/Backdrop/BackdropComponent";

function AuthCallback() {
  const { provider } = useParams();
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { data, isLoading } = useProviderLoginQuery({
    provider,
    search: location.search,
  });

  useEffect(() => {
    if (data?.jwt && data?.refreshToken && data?.user) {
      dispatch(setCredetnials(data));
      navigate("/", { replace: true });
    }
  }, [data]);

  return (
    <div>
      <BackdropComponent position="absolute" isLoading={isLoading} />
    </div>
  );
}

AuthCallback.propTypes = {
  history: PropTypes.shape({
    replace: PropTypes.func,
    push: PropTypes.func,
    location: PropTypes.shape({
      pathname: PropTypes.string,
    }),
  }),
};

export default AuthCallback;
