import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { HeaderContainer, HeaderSideContainer } from "./Header.styled";
import HeaderNavigation from "./HeaderNavigation/HeaderNavigation";
import Logo from "components/Logo/Logo";
import HeaderProfileBar from "./HeaderProfileBar/HeaderProfileBar";
import { selectCurrentUser } from "features/auth/authSlice";
import { PAGES } from "constants/pages";
import useDevice from "hooks/useDevice";
import HeaderDrawer from "./HeaderDrawer/HeaderDrawer";

const Header = () => {
  const user = useSelector(selectCurrentUser);
  const { isMobile } = useDevice();
  return (
    <HeaderContainer>
      <HeaderSideContainer>
        {isMobile && <HeaderDrawer />}
        {user && !isMobile && <HeaderNavigation />}
      </HeaderSideContainer>
      <HeaderSideContainer>
        {!isMobile && <HeaderProfileBar />}
        <Logo as={NavLink} to={PAGES.BASE.route} />
      </HeaderSideContainer>
    </HeaderContainer>
  );
};

Header.propTypes = {
  children: PropTypes.node,
};

export default Header;
