import React from "react";
import PropTypes from "prop-types";
import { ErrorMessage, Field, FieldContainer } from "./CustomField.styled";
import { InputAdornment } from "@mui/material";
import { AccountCircle } from "@mui/icons-material";

const CustomField = ({
  error,
  type,
  name,
  placeholder,
  label,
  value,
  handleChange,
  displayIcon,
}) => {
  return (
    <FieldContainer
      sx={{ display: "flex", flexDirection: "column", marginBottom: 1 }}
    >
      <Field
        sx={{
          "& fieldset": {
            border: error ? "1px solid #e90732" : "1px solid #858585",
            borderRadius: "8px",
          },
        }}
        type={type}
        label={label}
        name={name}
        value={value}
        placeholder={placeholder}
        onChange={handleChange}
        InputProps={{
          startAdornment: displayIcon && (
            <InputAdornment position="start">
              <AccountCircle />
            </InputAdornment>
          ),
        }}
      />
      <ErrorMessage variant="caption" sx={{ minHeight: "20px" }}>
        {error}
      </ErrorMessage>
    </FieldContainer>
  );
};

CustomField.propTypes = {
  name: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  error: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.any,
  handleChange: PropTypes.func,
  displayIcon: PropTypes.bool,
};

export default CustomField;
