import React from "react";
import PropTypes from "prop-types";
import { HeaderProfileBarContainer } from "./HeaderProfileBar.styled";
import HeaderThemeChooser from "./HeaderThemeChooser/HeaderThemeChooser";
import HeaderNotifications from "./HeaderNotifications/HeaderNotifications";
import HeaderProfile from "./HeaderProfile/HeaderProfile";
import { selectCurrentUser } from "features/auth/authSlice";
import { useSelector } from "react-redux";

const HeaderProfileBar = () => {
  const user = useSelector(selectCurrentUser);

  return (
    <HeaderProfileBarContainer>
      <HeaderThemeChooser />
      {user && (
        <>
          <HeaderNotifications />
          <HeaderProfile />
        </>
      )}
    </HeaderProfileBarContainer>
  );
};

HeaderProfileBar.propTypes = {
  children: PropTypes.node,
};

export default HeaderProfileBar;
