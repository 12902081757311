import React from "react";
import PropTypes from "prop-types";
import CustomField from "components/Fields/CustomField/CustomField";
import { FieldsContainer } from "../ProfileDetails.styled";
import { useTranslation } from "react-i18next";

const CompanyDataForm = ({ errors, values, handleChange }) => {
  const { t } = useTranslation();
  return (
    <FieldsContainer>
      <CustomField
        type="text"
        name="companyName"
        value={values.companyName}
        handleChange={handleChange}
        error={errors.companyName}
        label={t("profile.labels.companyName")}
      />
      <CustomField
        type="text"
        name="companyAddress"
        value={values.companyAddress}
        handleChange={handleChange}
        error={errors.companyAddress}
        label={t("profile.labels.companyAddress")}
      />
      <CustomField
        type="number"
        name="yearsInCompany"
        value={values.yearsInCompany}
        handleChange={handleChange}
        error={errors.yearsInCompany}
        label={t("profile.labels.yearsInCompany")}
      />
    </FieldsContainer>
  );
};

CompanyDataForm.propTypes = {
  errors: PropTypes.string,
  values: PropTypes.any,
  handleChange: PropTypes.func,
};

export default CompanyDataForm;
