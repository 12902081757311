import React from "react";
import ProfileImage from "./ProfileImage/ProfileImage";
import ProfileDetails from "./ProfileDetails/ProfileDetails";
import { makeErrorToastMessage } from "util/toastMessage";
import { ProfileContentContainer } from "./ProfileContent.styled";
import {
  useMyUserQuery,
  useUpdateUserInfoMutation,
} from "features/user/usersApiSlice";

const ProfileContent = () => {
  const [updateUserInfo] = useUpdateUserInfoMutation();
  const { data } = useMyUserQuery();

  const handleImage = (image) => {
    try {
      updateUserInfo({
        userInfo: { profileImage: image },
        userId: data?.id,
      });
    } catch (err) {
      makeErrorToastMessage(err.data.error.message);
    }
  };

  const handleSubmit = (values) => {
    try {
      updateUserInfo({
        userInfo: values,
        userId: data?.id,
      });
    } catch (err) {
      makeErrorToastMessage(err.data.error.message);
    }
  };

  return (
    <ProfileContentContainer>
      <ProfileImage handleImage={handleImage} />
      <ProfileDetails handleSubmit={handleSubmit} userData={data} />
    </ProfileContentContainer>
  );
};

export default ProfileContent;
