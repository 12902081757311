import styled from "styled-components";
import { Box, Button, Card, Step, StepLabel, Stepper } from "@mui/material";

export const FieldsContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  margin-top: 8px;
`;

export const FormContainer = styled(Card)`
  display: flex;
  flex-direction: column;
  padding: 16px;
`;

export const StepsContainer = styled(Box)``;

export const StepperContainer = styled(Stepper)``;

export const SingleStep = styled(Step)``;

export const SingleStepLabel = styled(StepLabel)``;

export const ContentContainer = styled(Box)``;

export const FormButtonsContainer = styled(Box)`
  display: flex;
  justify-content: space-between;
`;

export const FormButton = styled(Button)``;
