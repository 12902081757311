import { Divider } from "@mui/material";
import ImagePicker from "components/ImagePicker/ImagePicker";
import React from "react";
import PropTypes from "prop-types";
import { ImageContainer, Title, TitleContainer } from "./ProfileImage.styled";
import { useTranslation } from "react-i18next";

const ProfileImage = ({ handleImage }) => {
  const { t } = useTranslation();
  return (
    <ImageContainer elevation={5}>
      <TitleContainer sx={{ marginBottom: "80px" }}>
        <Title variant="h6">{t("profile.image")}</Title>
        <Divider />
      </TitleContainer>
      <ImagePicker
        handleImage={handleImage}
        descriptionText={t("profile.chooseImage")}
      />
    </ImageContainer>
  );
};

ProfileImage.propTypes = {
  handleImage: PropTypes.func,
};

export default ProfileImage;
