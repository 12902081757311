import { Box } from "@mui/material";
import { NavLink } from "react-router-dom";
import styled, { css } from "styled-components";

export const HeaderNavigationItemIconContainer = styled(Box)``;
export const HeaderNavigationItemIconTitle = styled(Box)`
  font-size: 14px;
  line-height: 20px;
  font-weight: ${(props) => props?.$isActive && 600};
  color: ${(props) => props?.theme?.colors?.textColor};
  font-weight: 500;
  padding: 0 7px;
`;

export const HeaderNavigationItemContainer = styled(NavLink)`
  display: flex;
  justify-content: center;
  text-decoration: none;
  align-items: center;
  width: fit-content;
  padding: 2px 6px;
  cursor: pointer;
  height: 100%;
  transition-duration: 0.1s;

  ${(props) =>
    props?.$isActive &&
    css`
      background-color: ${(props) => props?.theme.colors.primaryLighter};
    `}
  &:hover {
    background-color: ${(props) => props?.theme.colors.primaryLighter};
  }
  &[aria-checked="true"] ${HeaderNavigationItemIconTitle} {
    font-weight: 700;
  }
`;
