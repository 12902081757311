const random = (arr) => {
  return arr[Math.floor(Math.random() * arr.length)];
};

const size = () => {
  return random(["Extra Small", "Small", "Medium", "Large", "Extra Large"]);
};

const color = () => {
  return random(["Red", "Green", "Blue", "Orange", "Yellow"]);
};

const designer = () => {
  return random([
    "Ralph Lauren",
    "Alexander Wang",
    "Grayse",
    "Marc NY Performance",
    "Scrapbook",
    "J Brand Ready to Wear",
    "Vintage Havana",
    "Neiman Marcus Cashmere Collection",
    "Derek Lam 10 Crosby",
    "Jordan",
  ]);
};

const type = () => {
  return random([
    "Cashmere",
    "Cardigans",
    "Crew and Scoop",
    "V-Neck",
    "Shoes",
    "Cowl & Turtleneck",
  ]);
};

const price = () => {
  return (Math.random() * 100).toFixed(2);
};

function generate(count) {
  const data = [];
  for (let i = 0; i < count; i++) {
    const currentColor = color();
    const currentSize = size();
    const currentType = type();
    const currentDesigner = designer();
    const currentPrice = price();

    data.push({
      id: i,
      name: `${currentDesigner} ${currentType} ${currentColor} ${currentSize}`,
      color: currentColor,
      size: currentSize,
      designer: currentDesigner,
      type: currentType,
      price: currentPrice,
      salesPrice: currentPrice,
    });
  }
  return data;
}

export const chartData = [
  {
    name: "A",
    uv: 4000,
    pv: 2400,
    amt: 2400,
  },
  {
    name: "B",
    uv: 3000,
    pv: 1398,
    amt: 5000,
  },
  {
    name: "C",
    uv: 2000,
    pv: 9800,
    amt: 10000,
  },
  {
    name: "D",
    uv: 2780,
    pv: 3908,
    amt: 2000,
  },
  {
    name: "E",
    uv: 1890,
    pv: 4800,
    amt: 2181,
  },
  {
    name: "F",
    uv: 2390,
    pv: 3800,
    amt: 2500,
  },
  {
    name: "G",
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
];

export const chartBars = [
  { dataKey: "pv", fill: "#8884d8" },
  { dataKey: "uv", fill: "#82ca9d" },
];
export const radarData = [
  { subject: "Math", A: 120, B: 110, fullMark: 150 },
  { subject: "Chinese", A: 98, B: 130, fullMark: 150 },
  { subject: "English", A: 86, B: 130, fullMark: 150 },
  { subject: "Geography", A: 99, B: 100, fullMark: 150 },
  { subject: "Physics", A: 85, B: 90, fullMark: 150 },
  { subject: "History", A: 65, B: 85, fullMark: 150 },
];

export const radars = [
  {
    name: "Mike",
    dataKey: "A",
    stroke: "#8884d8",
    fill: "#8884d8",
  },
  {
    name: "Jenny",
    dataKey: "B",
    stroke: "#82ca9d",
    fill: "#82ca9d",
  },
];
export const pieData = [
  { name: "Tea", value: 400, color: "#0088FE" },
  { name: "Coffee", value: 300, color: "#00C49F" },
  { name: "Cola", value: 300, color: "#FFBB28" },
  { name: "Water", value: 200, color: "#FF8042" },
];

export default generate;
