import React from "react";
import PropTypes from "prop-types";
import CustomField from "components/Fields/CustomField/CustomField";
import { FieldsContainer } from "../ProfileDetails.styled";
import { useTranslation } from "react-i18next";

const PersonalDataForm = ({ errors, values, handleChange }) => {
  const { t } = useTranslation();
  return (
    <FieldsContainer>
      <CustomField
        type="text"
        name="firstName"
        value={values.firstName}
        handleChange={handleChange}
        error={errors.firstName}
        label={t("profile.labels.firstName")}
      />
      <CustomField
        type="text"
        name="lastName"
        value={values.lastName}
        handleChange={handleChange}
        error={errors.lastName}
        label={t("profile.labels.lastName")}
      />
      <CustomField
        type="text"
        name="gender"
        value={values.gender}
        handleChange={handleChange}
        error={errors.gender}
        label={t("profile.labels.gender")}
      />
    </FieldsContainer>
  );
};

PersonalDataForm.propTypes = {
  errors: PropTypes.any,
  values: PropTypes.any,
  handleChange: PropTypes.func,
};

export default PersonalDataForm;
