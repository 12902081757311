import React from "react";

const SettingsPage = () => {
  return (
    <>
      <p>Settings page</p>
    </>
  );
};

export default SettingsPage;
