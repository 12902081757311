import React, { useMemo } from "react";
import PropTypes from "prop-types";
import {
  HeaderLatestNotificationsContainer,
  HeaderNotificationsContentContainer,
  HeaderNotificationsContentHeader,
  HeaderNotificationsContentHeaderSeeMore,
  HeaderNotificationsContentHeaderTitle,
} from "./HeaderNotificationsContent.styled";
import SingleNotification from "./SingleNotification/SingleNotification";
import { useNotificationsQuery } from "features/user/usersApiSlice";
import { AccountCircle } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

const dummyNotification = {
  userPicture: <AccountCircle />,
  notificationText: (
    <p>
      <i>Olivia Saturday</i> commented on your post.
    </p>
  ),
  date: new Date(2023, 6, 10),
  seen: false,
};

const HeaderNotificationsContent = () => {
  const { data } = useNotificationsQuery(); //eslint-disable-line
  const { t } = useTranslation();

  const latestNotifications = useMemo(() => {
    // return data?.notifications?.slice(0, 3);
    return Array(3).fill(dummyNotification, 0);
  });
  return (
    <HeaderNotificationsContentContainer>
      <HeaderNotificationsContentHeader>
        <HeaderNotificationsContentHeaderTitle>
          {t("notifications.title")}
        </HeaderNotificationsContentHeaderTitle>
        <HeaderNotificationsContentHeaderSeeMore>
          {t("common.seeMore")}
        </HeaderNotificationsContentHeaderSeeMore>
      </HeaderNotificationsContentHeader>
      <HeaderLatestNotificationsContainer>
        {latestNotifications?.map?.((singleNotification, index) => (
          <SingleNotification
            key={index}
            notificationObject={singleNotification}
          />
        ))}
      </HeaderLatestNotificationsContainer>
    </HeaderNotificationsContentContainer>
  );
};

HeaderNotificationsContent.propTypes = {
  children: PropTypes.node,
};

export default HeaderNotificationsContent;
