import { Box } from "@mui/material";
import styled from "styled-components";

export const ProfileContentContainer = styled(Box)`
  display: flex;
  width: 100%;
  height: 90vh;
  gap: 10px;
  justify-content: center;
  align-items: center;
`;
