/*eslint-disable*/
import React from "react";
import PropTypes from "prop-types";
import { HeaderProfileContainer } from "./HeaderProfile.styled";
import PersonIcon from "@mui/icons-material/Person";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import PopoverComponent from "components/Popover/Popover";
import { HeaderIconContainer } from "components/Header/Header.styled";
import HeaderProfilePopoverContent from "./HeaderProfilePopoverContent/HeaderProfilePopoverContent";
const HeaderProfile = () => {
  return (
    <HeaderProfileContainer>
      <PopoverComponent
        contentContainerStyles={{
          "& .MuiPopover-paper": {
            borderRadius: "8px",
            overflow: "hidden",
            backgroundColor: "transparent",
          },
        }}
        trigger={
          <HeaderIconContainer>
            <AccountCircleIcon />
          </HeaderIconContainer>
        }
        content={<HeaderProfilePopoverContent />}
      />
    </HeaderProfileContainer>
  );
};

HeaderProfile.propTypes = {
  children: PropTypes.node,
};

export default HeaderProfile;
