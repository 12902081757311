import React from "react";
import PropTypes from "prop-types";
import { CardContainer, CardDetails, CardTitle } from "./ChartCard.styled";

const ChartCard = ({ title, children }) => {
  return (
    <CardContainer elevation={5}>
      <CardDetails sx={{ width: "100%" }}>
        <CardTitle sx={{ fontSize: 18 }} gutterBottom>
          {title}
        </CardTitle>
        {children}
      </CardDetails>
    </CardContainer>
  );
};

ChartCard.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
};
export default ChartCard;
