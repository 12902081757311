import styled from "styled-components";
import { Card, CardContent, Typography } from "@mui/material";

export const CardContainer = styled(Card)`
  display: flex;
  max-height: 370px;
  max-width: 600px;
  padding: 8px;
`;

export const CardDetails = styled(CardContent)`
  width: 100%;
`;

export const CardTitle = styled(Typography)`
  font-size: 18px;
`;
