import React from "react";
import {
  DashboardContainer,
  GridContainer,
  GridItem,
} from "./DashboardContnet.styled";
import ChartCard from "components/Cards/ChartCard/ChartCard";
import LineChartComponent from "components/Charts/LineChart/LineChart";
import PieChartComponent from "components/Charts/PieChart/PieChart";
import BarChartComponent from "components/Charts/BarChart/BarChart";
import RadarChartComponent from "components/Charts/RadarChart/RadarChart";
import {
  chartBars,
  chartData,
  pieData,
  radarData,
  radars,
} from "util/helpers/randomData";
import { useTranslation } from "react-i18next";

const DashboardContent = () => {
  const { t } = useTranslation();
  return (
    <DashboardContainer>
      <GridContainer container spacing={4}>
        <GridItem item xs={5}>
          <ChartCard title={t("dashboard.charts.lineChart")}>
            <LineChartComponent
              chartData={{
                data: chartData,
                XAxisKey: "name",
                YAxisKey: "amt",
                lines: chartBars,
              }}
            />
          </ChartCard>
        </GridItem>
        <GridItem item xs={5}>
          <ChartCard title={t("dashboard.charts.pieChart")}>
            <PieChartComponent
              chartData={{
                data: pieData,
                dataKey: "value",
              }}
            />
          </ChartCard>
        </GridItem>
        <GridItem item xs={5}>
          <ChartCard title={t("dashboard.charts.barChart")}>
            <BarChartComponent
              chartData={{
                data: chartData,
                XAxisKey: "name",
                YAxisKey: "amt",
                bars: chartBars,
              }}
            />
          </ChartCard>
        </GridItem>
        <GridItem item xs={5}>
          <ChartCard title={t("dashboard.charts.radarChart")}>
            <RadarChartComponent
              chartData={{
                data: radarData,
                AngleAxisKey: "subject",
                radars: radars,
              }}
            />
          </ChartCard>
        </GridItem>
      </GridContainer>
    </DashboardContainer>
  );
};

export default DashboardContent;
