import { useState, useMemo } from "react";
import {
  authScopeSetHelper,
  authScopeStringGetHelper,
} from "util/authScopeHelpers";
import { getTheme } from "themes";
import {
  getNextTheme,
  themeStorageKey,
  themes,
} from "constants/themeConstants";

const useTheme = () => {
  const currentColorMode =
    authScopeStringGetHelper(themeStorageKey) || themes[0]?.name;
  const [currentTheme, setCurrentTheme] = useState(currentColorMode);

  const changeTheme = (newTheme) => {
    const nextMode = newTheme || getNextTheme(currentTheme);
    authScopeSetHelper(themeStorageKey, nextMode);
    setCurrentTheme(nextMode);
  };

  const theme = useMemo(() => getTheme(), [currentTheme]);

  return [changeTheme, theme, currentTheme];
};

export default useTheme;
