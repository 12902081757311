import { apiSlice } from "features/api/apiSlice";

export const postsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    allPosts: builder.query({
      query: () => ({
        url: "api/posts",
      }),
    }),
  }),
});

export const { useAllPostsQuery } = postsApiSlice;
