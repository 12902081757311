import {
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
  getExpandedRowModel,
} from "@tanstack/react-table";
import PropTypes from "prop-types";
import { useState } from "react";
import React from "react";
import data from "../BasicTable/movieData.json";
import { columns } from "../BasicTable/tableData";
import {
  GlobalFilterInput,
  MainContainer,
  Title,
} from "../BasicTable/BasicTable.styled";
import TableControllButtons from "../BasicTable/TableControllButtons/TableControllButtons";
import Table from "../BasicTable/Table/Table";

const ExpandingTable = () => {
  const [sorting, setSorting] = useState([]);
  const [filtering, setFiltering] = useState("");

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getRowCanExpand: () => true,
    state: {
      sorting: sorting,
      globalFilter: filtering,
    },
    onSortingChange: setSorting,
    onGlobalFilterChange: setFiltering,
  });

  return (
    <MainContainer>
      <Title variant="h4" gutterBottom align="center">
        Primer tabele sa proširujućim redom
      </Title>
      <GlobalFilterInput
        value={filtering}
        onChange={(e) => setFiltering(e.target.value)}
      />
      <Table table={table} />
      <TableControllButtons table={table} />
    </MainContainer>
  );
};

ExpandingTable.propTypes = {
  data: PropTypes.array,
  columns: PropTypes.array,
};

export default ExpandingTable;
