import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
} from "@mui/material";
import styled from "styled-components";

export const Container = styled(Box)`
  display: flex;
  margin-bottom: -60px;
`;
export const CheckboxFormControl = styled(FormControl)``;

export const CheckboxFormLabel = styled(FormLabel)``;

export const CheckboxFormGroup = styled(FormGroup)``;

export const CheckboxFormControlLabel = styled(FormControlLabel)``;

export const CustomCheckbox = styled(Checkbox)``;
