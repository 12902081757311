import React from "react";
import PropTypes from "prop-types";
import {
  CustomTable,
  TBody,
  TCell,
  THead,
  THeadContainer,
  TRow,
  TableContainer,
} from "./Table.styled";
import { Info } from "@mui/icons-material";
import { flexRender } from "@tanstack/react-table";

const Table = ({ table }) => {
  return (
    <TableContainer elevation={5}>
      <CustomTable>
        <THead>
          {table.getHeaderGroups().map((headerGroup) => (
            <TRow key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <TCell
                  key={header.id}
                  onClick={header.column.getToggleSortingHandler()}
                >
                  {header.isPlaceholder ? null : (
                    <THeadContainer>
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                      {
                        { asc: "🔼", desc: "🔽" }[
                          header.column.getIsSorted() ?? null
                        ]
                      }
                    </THeadContainer>
                  )}
                </TCell>
              ))}
            </TRow>
          ))}
        </THead>

        <TBody>
          {table.getRowModel().rows.map((row) => (
            <>
              <TRow key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <TCell key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </TCell>
                ))}
              </TRow>

              {row.getIsExpanded() && (
                <TRow sx={{ borderBottom: `1px solid rgba(224, 224, 224, 1)` }}>
                  <TCell>
                    <Info />
                  </TCell>
                  <TCell>{row.original.name}</TCell>
                </TRow>
              )}
            </>
          ))}
        </TBody>
      </CustomTable>
    </TableContainer>
  );
};

Table.propTypes = {
  table: PropTypes.any,
};

export default Table;
