import { Box, Button } from "@mui/material";
import styled from "styled-components";

export const ButtonsContainer = styled(Box)`
  display: flex;
  justify-content: flex-end;
`;

export const DeleteButton = styled(Button)`
  min-width: 30px;
`;

export const EditButton = styled(Button)`
  min-width: 30px;
`;

export const ExpandButton = styled(Button)`
  min-width: 30px;
`;

export const RowIcon = styled(Button)``;
