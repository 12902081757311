import React from "react";
import { Route, Routes } from "react-router-dom";

import LoginPage from "./pages/LoginPage/LoginPage";
import RegisterPage from "pages/RegisterPage/RegisterPage";
import HomePage from "pages/HomePage/HomePage";
import RequireAuth from "components/RequireAuth/RequireAuth";
import AuthCallback from "pages/AuthCallbackPage/AuthCallbackPage";
import ProfilePage from "pages/ProfilePage/ProfilePage";
import SettingsPage from "pages/SettingsPage/SettingsPage";
import Error from "pages/ErrorPage/ErrorPage";
import { PAGES } from "constants/pages";
import DashboardPage from "pages/DashboardPage/DashboardPage";
import ComponentsPage from "pages/ComponentsPage/ComponentsPage";

const AppRoutes = () => (
  <Routes>
    <Route
      path={PAGES.LOGIN.route}
      element={<LoginPage />}
      errorElement={<Error />}
    />
    <Route
      exact
      path={PAGES.REGISTER.route}
      element={<RegisterPage />}
      errorElement={<Error />}
    />
    <Route path="api/auth/:provider/callback" element={<AuthCallback />} />
    <Route element={<RequireAuth />} errorElement={<Error />}>
      <Route path={PAGES.HOME.route} element={<HomePage />} />
      <Route path={PAGES.BASE.route} element={<HomePage />} />
      <Route path={PAGES.PROFILE.route} element={<ProfilePage />} />
      <Route path={PAGES.SETTINGS.route} element={<SettingsPage />} />
      <Route path={PAGES.DASHBOARD.route} element={<DashboardPage />} />
      <Route path={PAGES.COMPONENTS.route} element={<ComponentsPage />} />
    </Route>
  </Routes>
);

export default AppRoutes;
