import PropTypes from "prop-types";
import React from "react";
import { PieChart, Pie, Legend, Cell, ResponsiveContainer } from "recharts";

const PieChartComponent = ({ chartData }) => {
  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos((-midAngle * Math.PI) / 180);
    const y = cy + radius * Math.sin((-midAngle * Math.PI) / 180);

    return (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
      >
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };

  return (
    <ResponsiveContainer width="100%" height="100%">
      <PieChart>
        <Legend layout="vertical" verticalAlign="top" align="top" />
        <Pie
          data={chartData?.data}
          labelLine={false}
          label={renderCustomizedLabel}
          outerRadius={140}
          cx="50%"
          cy="30%"
          fill="#8884d8"
          dataKey={chartData?.dataKey}
        >
          {chartData?.data.map((entry, index) => (
            <Cell
              key={`cell-${index}`}
              fill={entry.color}
              style={{ outline: "none" }}
            />
          ))}
        </Pie>
      </PieChart>
    </ResponsiveContainer>
  );
};

PieChartComponent.propTypes = {
  chartData: PropTypes.object.isRequired,
};
export default PieChartComponent;
